import React, { useState } from "react";

const ResponsiveList = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const listItems = [
    {
      id: 1,
      title: "Montaje și reparații acoperișuri",
      details:
        "Specializați în țiglă metalică, oferim servicii complete de montaj și reparație a acoperișurilor. Indiferent dacă este vorba despre înlocuirea țiglelor, repararea unor infiltrații sau instalarea unui nou sistem de acoperiș, echipa noastră de specialiști este pregătită să asigure o execuție de calitate superioară, folosind materiale durabile și tehnici moderne.",
    },
    {
      id: 2,
      title: "Dulgherie acoperișuri",
      details:
        "Serviciile noastre de dulgherie acoperișuri includ construcția și repararea structurii de suport a acoperișului. Ne ocupăm de realizarea șarpantelor din lemn, asigurându-ne că acestea respectă cele mai înalte standarde de siguranță și durabilitate. Colaborăm cu clienții pentru a personaliza soluțiile în funcție de specificațiile fiecărui proiect.",
    },
    {
      id: 3,
      title: "Montaj burlane, jgheaburi și parazăpezi",
      details:
        "Ne ocupăm de montajul burlanelor, jgheaburilor și parazăpezilor pentru a asigura o gestionare eficientă a apei pluviale. Aceste sisteme sunt esențiale pentru protejarea fundației clădirii și prevenirea deteriorării acoperișului. Folosim materiale de calitate superioară și tehnici avansate pentru a garanta funcționalitatea și estetica acestora.",
    },
  ];

  const toggleDetails = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="flex w-full justify-center ">
      <ul className="flex flex-wrap lg:flex-nowrap max-w-screen-xl  mx-2">
        {listItems.map((item, index) => (
          <li key={item.id} className="w-full lg:flex-1 lg:basis-1/3 lg:px-2">
            <div className="flex flex-col h-full">
              {/* Title Section */}
              <div
                className={`bg-[#9C75B2] text-white text-lg font-medium
                  w-full
                  ${index === 0 ? "mt-0" : "mt-4"} sm:mt-0 md:mt-4
                  rounded-lg lg:rounded-t-lg lg:rounded-none`}
              >
                <button
                  onClick={() => toggleDetails(index)}
                  className="w-full px-4 py-2 text-center"
                >
                  {item.title}
                </button>
              </div>

              <div
                className={`transition-all duration-300 ease-in-out w-full
                  ${
                    openIndex === index || window.innerWidth >= 1024
                      ? "max-h-[600px] opacity-100 overflow-visible"
                      : "max-h-0 opacity-0 overflow-hidden"
                  }`}
              >
                <div
                  className="bg-[#effdff] text-sm text-gray-700 p-4
                    rounded-lg lg:rounded-t-none lg:rounded-b-lg
                    mt-3 lg:mt-0"
                >
                  {item.details}
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ResponsiveList;
